import React from "react";

import Headings from "./Headings";
import Slider from "react-slick";
import BlogItem from "./Blog/BlogItem";

import styles from "../styles/style";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ArticleSlider = ({ articles }) => {
  //console.log("Article: ", articles);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    slidesPerRow: 1,
    arrows: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      id="articles"
      className={`bg-[#ffffff] mb-8 ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`mb-8 ${styles.boxWidth}`}>
        <Headings
          titleTop="Featured articles"
          titleBottom="latest news"
          description="Keep up to date with everything Intact Electrical. Check out what we've been up to, including the work we're doing in collaboration with our partners in the local community."
        />
        <Slider {...settings}>
          {articles.map((article, index) => {
            //console.log("Item: ", article.frontmatter.title);
            return (
              <BlogItem
                key={index}
                alt={article.frontmatter.title}
                image={
                  article.frontmatter.image.childImageSharp.gatsbyImageData
                }
                slug={article.frontmatter.slug}
                title={article.frontmatter.title}
                excerpt={article.frontmatter.excerpt}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
