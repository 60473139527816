import React from "react";
import { graphql, useStaticQuery } from "gatsby";

//import Helmet from "react-helmet";
import HeroV7 from "../components/HeroV7.js";
import TrustedServices from "../components/TrustedServices.js";
import FeaturedContent from "../components/FeaturedContent.js";
import PageAdvert from "../components/PageAdvert.js";
import FeaturedProject from "../components/FeaturedProject";
import ReactLightboxV2 from "../components/ReactLightboxV2";
import { ArticleSlider } from "../components/ArticleSlider.js";
import TestimonialSliderDark from "../components/TestimonialSliderDark.js";
import Seo from "../components/Seo.js";


import {
  adverts,
  homeFeaturedContent,
  medivetProjectDetails,
  medivetProjectHeader,
  homeGalleryHeader,
  feedback,
} from "../constants";

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "blog-post" }, featured: { eq: true } }
        }
      ) {
        nodes {
          frontmatter {
            title
            tag
            slug
            excerpt
            featured
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 1.4
                )
              }
            }
          }
          html
        }
      }
      site {
				siteMetadata {
					siteUrl
				}
			}
      featuredContentImages: allFile(
        filter: {
          name: {
            in: ["01-harvey-nichols-logo", "02-dhl-logo", "03-biffa-logo"]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      medivetProjectImages: allFile(
        filter: {
          name: {
            in: [
              "02-medivet-project"
              "04-medivet-project"
              "05-medivet-project"
              "12-medivet-project"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
      advertImages: allFile(
        filter: {
          name: {
            in: [
              "06-domestic-external-lighting"
              "02-commercial-electrical-services"
              "nic-eic-v7"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.3
            )
          }
        }
      }
      galleryImages: allFile(
        filter: {
          name: {
            in: [
              "01-domestic-electrical-services"
              "dhl-gallery-16"
              "250"
              "04-ladbrokes-project"
              "12-medivet-project"
              "02-harvey-nicholes-chiller-upgrade"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
      imageUrl: file(
        name: {
          eq:  "intact-electrical-full-info-tranparent"            
        } 
      ) {        
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
        publicURL        
      }
    }
  `);

  //console.log("data: ", data);

  // advert details
  const domesticAdvertDetails = adverts.find((ad) => {
    return ad.id === "domestic-electrical-services";
  });

  const commercialAdvertDetails = adverts.find((ad) => {
    return ad.id === "commercial-electrical-services";
  });

  const certificationsAdvertDetails = adverts.find((ad) => {
    return ad.id === "training-and-certifications";
  });

  // advert images
  const domesticAdvertImage = data.advertImages.nodes.find((image) => {
    return image.name === "06-domestic-external-lighting";
  });

  const commercialAdvertImage = data.advertImages.nodes.find((image) => {
    return image.name === "02-commercial-electrical-services";
  });

  const certificationsAdvertImage = data.advertImages.nodes.find((image) => {
    return image.name === "nic-eic-v7";
  });

  const articles = data.allMarkdownRemark.nodes;
  const featuredContentImages = data.featuredContentImages.nodes;

 
  const siteUrl = data.site.siteMetadata.siteUrl
  //console.log("siteUrl: ", siteUrl)


  //seo image url
  const imageUrl = siteUrl + data.imageUrl.publicURL
  //console.log("imagelUrl: ", imageUrl)

  const structuredData = (
		<script type="application/ld+json">
		  {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Intact Electrical Ltd",
        "image": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif",
        "@id": "https://intact-electrical.co.uk",
        "url": "https://intact-electrical.co.uk",
        "telephone": "01628 243041",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "46 Nursery Rd",
          "addressLocality": "Taplow",
          "postalCode": "SL6 0JZ",
          "addressCountry": "GB",
          "addressRegion": "Maidenhead"
        },
        "priceRange": "£££",
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "51.52365",
          "longitude": "-0.6642591"
        },
        "sameAs": [
          "https://www.facebook.com/intactelectrics",
          "https://uk.linkedin.com/company/intact-electrical-limited",
          "https://www.instagram.com/intactelectricalltd"
        ],
        "openingHoursSpecification": [
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday"
            ],
            "opens": "09:00",
            "closes": "21:00"
          },
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Saturday",
              "Sunday"
            ],
            "opens": "08:00",
            "closes": "15:00"
          }
        ]
      })}
		</script>
	)

  return (
    <>
      <Seo
        title="Home"
        description="Intact Electrical, Electrical services you can trust, Domestic commercial industrial electrical services, electrical installations"
        image={imageUrl}
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV7 />
      <TrustedServices />
      <FeaturedContent
        featuredContent={homeFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <PageAdvert ad={domesticAdvertDetails} image={domesticAdvertImage} />
      <PageAdvert ad={commercialAdvertDetails} image={commercialAdvertImage} />
      <PageAdvert
        ad={certificationsAdvertDetails}
        image={certificationsAdvertImage}
      />

      <FeaturedProject
        projectDetails={medivetProjectDetails}
        projectImages={data.medivetProjectImages.nodes}
        projectHeader={medivetProjectHeader}
      />

      <ReactLightboxV2
        images={data.galleryImages.nodes}
        galleryContent={homeGalleryHeader}
        location="home-page"
      />
      <ArticleSlider articles={articles} />

      <TestimonialSliderDark feedback={feedback} />
    </>
  );
};

export default Index;
